<template>
  <div class="enterprise" v-wechat-title="$route.meta.title">
    <navigation msg="账号信息"></navigation>
    <div class="guiding">
      根据国家相关部门最新规定，企业及个人需完成实名认证后，才可以查看候选人/员工的背景调查报告
    </div>

    <van-action-sheet
      v-model="showes"
      :actions="actions"
      @select="getpeoplelength"
    />
    <!-- <div class="bk"></div> -->
    <div class="from">
      <van-form @submit="onSubmit">
        <van-field
          :disabled="iscanmodify"
          v-model="company"
          label="企业名称"
          required
          placeholder="请填写完整的企业名称"
          :rules="[{ required: true, message: '请填写企业名称' }]"
        />
        <div class="daimaclass">
          <van-field
            readonly="readonly"
            v-model="enterprise_tax_number"
            required
            label="公司信用代码"
            placeholder="获取公司信用代码"
          />
          <!-- v-show="iscanmodify == false" -->
          <button
            v-show="iscanmodify == false"
            @click.stop="getcompony"
            id="buttonid"
          >
            获取信用代码
          </button>
        </div>
        <van-field
          v-model="tel"
          :disabled="iscanmodify"
          required
          label="联系电话"
          placeholder="请正确填写，以便快递送达"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
          v-model="email"
          :disabled="iscanmodify"
          required
          label="邮箱"
          placeholder="请填写邮箱"
          :rules="[{ required: true, message: '请填写企业邮箱' }]"
        />
        <van-field
          :disabled="iscanmodify"
          readonly="readonly"
          v-model="number"
          @click="onFocus"
          required
          label="公司人数"
          placeholder="公司人数"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
          v-model="company_culture"
          label="企业文化"
          placeholder="请输入企业文化"
        />
        <van-field
          v-model="company_build"
          label="团队建设"
          placeholder="请输入团队建设"
        />
        <div id="sc">
          <van-field required name="uploader" label="营业执照">
            <template #input>
              <van-uploader
                :disabled="true"
                :max-size="10 * 1024 * 1024"
                :max-count="1"
                v-model="uploader"
              >
              </van-uploader>
            </template>
          </van-field>
          <div class="ticla">
            如营业执照不清晰可能导致账号暂停使用<br />请确保营业执照无污点无遮挡，正面上传
          </div>
        </div>
        <van-field
          :disabled="iscanmodify"
          readonly="readonly"
          clickable
          name="area"
          :value="
            value == 'undefined/undefined/undefined'
              ? ''
              : value.indexOf('undefined') != -1
              ? value.replaceAll('undefined', '')
              : value
          "
          label="地区选择"
          placeholder="点击选择省市区"
          @click="clickshowArea"
        />
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="onConfirm"
            @cancel="showArea = false"
            :columns-placeholder="['请选择', '请选择', '请选择']"
          />
        </van-popup>

        <van-field
          :disabled="iscanmodify"
          v-model="address"
          label="办公地址"
          placeholder="办公地址"
        />

        <van-field
          :disabled="iscanmodify"
          readonly="readonly"
          @click="clickshowes"
          v-model="industry"
          label="行业"
          placeholder="行业"
        />
        <van-action-sheet
          v-model="showes1"
          :actions="hangyelist"
          @select="hangyemeth"
        />

        <div id="gr">
          <div>管理员信息</div>
          <div>
            具有招聘资质的企业人员才可进行背景调查；<br />平台会对您的信息严格保密
          </div>
        </div>
        <van-field
          v-model="username"
          :disabled="iscanmodify"
          required
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
          v-model="mobile"
          readonly="readonly"
          required
          label="手机号"
          placeholder="手机号"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
          @click="clickshowes2"
          readonly="readonly"
          :disabled="iscanmodify"
          v-model="position"
          required
          label="职位"
          placeholder="职位"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-action-sheet
          v-model="showes2"
          :actions="zhiweilist"
          @select="zhiweimeth"
        />

        <div id="sc2" class="sc2">
          <van-field :disabled="true" name="uploader" label="工牌/名片">
            <template #input>
              <van-uploader
                :disabled="true"
                :max-count="1"
                v-model="uploadergo"
              >
              </van-uploader>
            </template>
          </van-field>
        </div>
        <div class="lipin">
          <div>完成企业认证，请至“首页”联系客服，领取免费体验！</div>
        </div>
        <div class="information">
          <div class="myji">
            <div class="jifen">我的积分：{{ usable_point }}分</div>
            <img
              @click="$router.push('/integral')"
              src="../../assets/jifen.png"
              alt=""
            />
          </div>
        </div>
        <div class="button">
          <van-button
            class="upclass"
            style="font-size: 0.2rem; width: 100%"
            round
            block
            type="info"
            native-type="submit"
            size="normal"
            color="linear-gradient(to right, #f55714, #fc9e46)"
          >
            <!-- v-if="iscanmodify!=true" -->
            修改信息
          </van-button>
          <!-- <van-button
            color="linear-gradient(to right, #fcd0bd, #fcd0bd)"
            round
            action="/api/upload"
            type="primary"
            @click="back"
            >取消</van-button
          > -->
        </div>
      </van-form>
    </div>
    <div style="height: 0.5rem"></div>

    <!-- <div v-show="isren==true" class="msk"></div>
    <div v-show="isren==true" calss="renzhengclassthe">认证中</div> -->
  </div>
</template>

<script>
import Addressinfo from "../../../util/Area";
import city from "../../../util/city";
import citytransit from "../../../util/citytransit";
import axios from "axios";
import { Toast } from "vant";
import base from "../../../util/base";
// import { province_list } from "../../../util/Area";
export default {
  mounted() {
    /**
     * 查询积分
     */
    this.$http
      .post("/firm/v1/Userinfo/usablePoint", {
        reqType: "userinfo",
      })
      .then((res) => {
        this.usable_point = JSON.parse(res.data).data.usable_point;
      });

    this.$http
      .post("/firm/v1/userinfo/companyinfo", {
        reqType: "userinfo",
      })
      .then((res) => {
        //企业审核通过后，禁止修改数据
        if (JSON.parse(res.data).data.status == 3) {
          this.iscanmodify = true;
          // this.iscanmodify = false;
        } else {
          this.iscanmodify = false;
        }

        let enterpricemsg = JSON.parse(res.data).data;

        this.a_id = enterpricemsg.a_id; //企业id
        if (enterpricemsg.status == "3") {
          this.left = "";
        }

        (this.company_culture = enterpricemsg.company_culture), //企业文化
          (this.company_build = enterpricemsg.company_build), //团队建设
          (this.enterprise_tax_number = enterpricemsg.enterprise_tax_number);
        this.company = enterpricemsg.company; //企业名称
        this.address = enterpricemsg.address;
        this.email = enterpricemsg.email; //企业邮箱
        var pattern3 = new RegExp("[0-9]+");

        var str3 = enterpricemsg.province;

        if (pattern3.test(str3)) {
          this.value = citytransit.tomsgcity(
            enterpricemsg.province +
              "/" +
              enterpricemsg.city +
              "/" +
              enterpricemsg.district
          );
          this.upvalue = this.value;
        } else {
          this.value =
            enterpricemsg.province +
            "/" +
            enterpricemsg.city +
            "/" +
            enterpricemsg.district;
          this.upvalue = this.value;
        }

        (this.industry = enterpricemsg.industry), //行业
          (this.tel = enterpricemsg.tel), //电话
          (this.number = enterpricemsg.number); //公司人数
        if (enterpricemsg.license != undefined && enterpricemsg.license != "") {
          this.uploader.push({ url: enterpricemsg.license }); //营业执照
          this.imgsrc1 = enterpricemsg.license; //图片地址
        }
        if (
          enterpricemsg.work_card != undefined &&
          enterpricemsg.work_card != ""
        ) {
          this.uploadergo.push({ url: enterpricemsg.work_card }); //工牌
          this.imgsrc2 = enterpricemsg.work_card; //图片地址
        }
        (this.username = enterpricemsg.username), //姓名
          (this.position = enterpricemsg.position); //职位
      });
  },

  data() {
    return {
      reg_cap: "", //注册资金
      ent_status: "", //公司状态
      iscanmodify: true,
      msg: "企业认证",
      verify: {
        username: [{ required: true, message: "请填写企业名称" }],
      },
      uploader: [],
      value: "",
      value1: "",
      showArea: false,
      areaList: Addressinfo, // 数据格式见 Area 组件文档
      arrArea: [],
      address1: "",
      //提交需要的参数
      company: "",
      address: "",
      number: "",
      tel: "",
      username: "",
      position: "",
      industry: "互联网",
      mobile: localStorage.getItem("phone"),
      uploader: [], // 营业执照
      uploadergo: [], // 工牌
      imgsrc1: "", //图片地址(上传使用)（营业执照）
      imgsrc2: "", //图片地址(上传使用)（工牌）
      left: "noleft",
      show: false,
      showes: false,
      showes1: false, //行业选择
      showes2: false, //职位选择
      actions: [
        { name: "20人以下" },
        { name: "20-99人" },
        { name: "100-499人" },
        { name: "500人以上" },
      ],
      hangyelist: [], //行业数组
      zhiweilist: [], //职位数组
      enterprise_tax_number: "", //公司信用代码
      isren: true,
      email: "", //企业邮箱
      canup: true,
      company_culture: "", //企业文化
      company_build: "", //团队建设
      usable_point: "", //积分
      a_id: "", //企业id
    };
  },

  methods: {
    clickleft() {
      this.$router.push("/home");
    },
    /**
     * 点击职位
     */
    clickshowes2() {
      if (this.iscanmodify == true) return;
      this.showes2 = true;
    },
    /**
     * 点击行业
     */
    clickshowes() {
      if (this.iscanmodify == true) return;
      this.showes1 = true;
    },
    /**
     * 点击地区选择
     */
    clickshowArea() {
      if (this.iscanmodify == true) return;
      this.showArea = true;
    },
    /** 
      获取信用代码
    */
    getcompony() {
      if (this.company == "") {
        this.$toast("请输入公司名称");
        return;
      }
      Toast.loading();
      this.$http
        .post("/firm/v1/Userinfo/getCreditCode", {
          reqType: "userinfo",
          company_name: this.company,
        })
        .then((res) => {
          this.enterprise_tax_number = JSON.parse(res.data).data.credit_code;
        });
    },
    zhiweimeth(msg) {
      this.showes2 = false;
      this.position = msg.name;
    },
    hangyemeth(msg) {
      this.showes1 = false;
      this.industry = msg.name;
    },
    /**
     * 选择行业信息
     */
    clickhangye() {
      this.$http
        .post("/firm/v1/userinfo/info", {
          reqType: "userinfo",
        })
        .then((res) => {
          let themsg = JSON.parse(res.data).data;
          this.hangyelist = themsg.industry;
          this.zhiweilist = themsg.position;
          this.zhiweilist.unshift({
            name: "部门经理",
          });
        });
    },
    onFocus() {
      if (this.iscanmodify == true) return;
      this.showes = true;
    },
    /***
      选择企业人数
     */
    getpeoplelength(msg) {
      this.showes = false;
      this.number = msg.name;
    },
    /**
     * 上传文件到服务器
     * file:需要上传的文件
     * msg:保存返回的地址（判断保存到的变量位置）
     */
    uploadImg() {},
    onConfirm(values) {
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
      this.upvalue = citytransit.tocodecity(this.value);
    },

    /**
     *
     * 修改信息
     */
    async onSubmit() {
      this.$http
        .post("/firm/v1/Userinfo/companyinfoEdit", {
          reqType: "userinfo",
          a_id: this.a_id,
          company_culture: this.company_culture, //企业文化
          company_build: this.company_build, //团队建设
        })
        .then((res) => {
          this.$toast("修改成功");
          this.$router.go(0);
        });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.van-uploader__preview-image {
  width: 2rem;
  height: 2rem;
}
.menuclasses {
  display: flex;
  align-items: center;
}
.iconclass {
  width: 0.35rem;
  margin-right: 0.1rem;
  margin-top: -0.08rem;
}
.tohomeclass {
  color: #f9814f;
}
.renzhengclassthe {
  position: fixed;
  background-color: #ffffff;
  font-size: 5rem;
  z-index: 1000;
  top: 0;
}
.daimaclass {
  display: flex;
  align-items: center;
}
#buttonid {
  min-width: 1.6rem;
  border: none;
  padding: 0.1rem 0;
  border-radius: 0.1rem;
  background-color: #fb8f3c;
  color: #ffffff;
}
#bt {
  width: 100%;
  border: none;
  padding: 0.26rem 0;
  background-color: #f65a16;
  color: #ffffff;
  font-weight: 0.32rem;
  line-height: 0.44rem;
}
.van-popup--center {
  width: 70%;
  border-radius: 0.2rem;
}
.qicl {
  padding: 1rem 0.47rem;
  font-size: 0.34rem;
  line-height: 0.68rem;
  font-weight: 500;
}
.lipin {
  color: #f55714;
  font-size: 0.24rem;
  margin: 0.3rem 0;
}
#gr {
  margin-top: 0.83rem;
  margin-bottom: 0.21rem;
  width: 100%;
  font-size: 0.24rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #616b80;
  line-height: 0.33rem;
}
#gr > div:first-child {
  color: #616b80;
  font-size: 0.24rem;
  line-height: 0.33rem;
}
#gr > div:nth-child(2) {
  color: #f55714;
  font-size: 0.24rem;
  line-height: 0.33rem;
  margin-top: 0.06rem;
}
#sc .ticla {
  position: absolute;
  bottom: 0.3rem;
  margin-left: 0.8rem;
  color: #f51313;
  font-weight: bold;
  font-size: 0.2rem;
}
#sc2 .ticla {
  position: absolute;
  bottom: 0.3rem;
  margin-left: 0.8rem;
  color: #f51313;
  font-weight: bold;
  font-size: 0.2rem;
}
.guiding {
  background-color: #fef1ec;
  color: #f55613;
  font-size: 0.24rem;
  line-height: 0.33rem;
  padding: 0.2rem 0.32rem;
  margin: 0.22rem 0;
}
/deep/ .van-button__text {
  font-size: 0.36rem;
}
.upclass {
  color: red;
}
.enterprise {
  background-color: #ffffff;
  .bk {
    width: 100%;
    height: 0.01rem;
    border: 0.01rem solid #eeeeee;
  }
  .from {
    padding: 0 0.3rem;
    /deep/ .van-cell {
      width: 100%;
      height: 0.84rem;
      background: #ffffff;
      font-size: 0.24rem;
      box-shadow: 0rem 0.02rem 0rem 0rem #ebeef5;
      line-height: 0.67rem;
      .van-field__label {
        width: 1.47rem;
        height: 0.4rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #697386;
      }
    }
    /deep/.van-uploader__upload {
      width: 2.6rem;
      height: 2.6rem;
    }
    /deep/ .van-uploader__input {
      width: 2.6rem;
      height: 2.6rem;
    }
    /deep/ .van-cell::after {
      border-bottom: 0.02rem solid#ebeef5 !important;
    }
    #sc {
      width: 100%;
      position: relative;
      padding-bottom: 1rem;
      .van-cell {
        height: 100%;
        box-shadow: none !important;

        /deep/.van-icon-photograph::before {
          content: "";
          width: 2.6rem;
          height: 2.6rem;
          background-image: url("../../assets/tianjia.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }

    #sc2 {
      width: 100%;
      position: relative;
      .van-cell {
        height: 100%;
        box-shadow: none !important;

        /deep/.van-icon-photograph::before {
          content: "";
          width: 2.6rem;
          height: 2.6rem;
          background-image: url("../../assets/tianjia.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }

    .button {
      display: flex;

      .van-button--block {
        width: 50%;
        height: 0.96rem;
        font-size: 0.36rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .van-button--primary {
        margin-left: 0.24rem;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        width: 50%;
        height: 0.96rem;
      }
    }
  }
}
.enterprise .from >>> .sc2 {
  padding-bottom: 0;
}
.myji {
  margin-top: 0.21rem;
  display: flex;
  align-items: center;
}
.myji img {
  width: 0.3rem;
  height: 0.3rem;
}
.jifen {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #616b80;
  line-height: 0.4rem;
  margin-right: 0.13rem;
}
.btdiv {
  padding-bottom: 0.3rem;
}
input {
  background-color: white;
  -webkit-opacity: 1;
  opacity: 1;
}

#code {
  text-align: center;
  max-width: 2rem;
}
.getcodeclass {
  color: #f55613;
}
.thebu {
  margin-top: 2rem;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
.information {
  margin: 0.59rem 0.54rem;
  font-size: 0.28rem;
  color: #697386;
  margin-left: 0;
}
.in1 {
  padding: 0.22rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
input {
  text-align: right;
  border: none;
  width: 4.5rem;
}
input::placeholder {
  color: #c1c5ce;
}
</style>
